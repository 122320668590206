import {forEach} from "./helpers";
import Glide
	from "@glidejs/glide";

export const usps = () => {
	const usps = document.querySelectorAll('.block--usps');
	if (usps.length > 0) {
		forEach(usps, (index, uspBlock) => {
			const glide = uspBlock.querySelector('.glide');

			if (glide) {
				let gap = glide.getAttribute('data-gap') ? glide.getAttribute('data-gap') : 15;
				let perview = 1;
				let focusat = 0;

				let mounted = new Glide(glide, {
					type: 'carousel',
					startAt: 0,
					gap: gap,
					perView: perview,
					focusAt: focusat,
				}).mount();
			}
		});
	}
}
