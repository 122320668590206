import {
	forEach
} from "./helpers";
import Glide
	from "@glidejs/glide";

export const quoteSlider = () => {
	const quoteSliders = document.querySelectorAll('.block--quote-slider');
	if (quoteSliders.length > 0) {
		forEach(quoteSliders, (index, quoteSliderBlock) => {
			const glide = quoteSliderBlock.querySelector('.glide');

			if (glide) {
				let gap = glide.getAttribute('data-gap') ? glide.getAttribute('data-gap') : 0;
				let perview = 1;
				let focusat = 0;

				let mounted = new Glide(glide, {
					type: 'slider',
					startAt: 0,
					gap: gap,
					perView: perview,
					focusAt: focusat,
					rewind: false,
				}).mount();
			}
		});
	}
}
