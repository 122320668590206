import {
	forEach,
	throttle
} from "./helpers";

export const footer = () => {
	const footer = document.querySelector('.footer');
	const footerMenuTriggers = footer.querySelectorAll('.footer-menu-trigger');

	if (footerMenuTriggers.length > 0) {
		forEach(footerMenuTriggers, (index, footerMenuTrigger) => {
			const menuDrawer = footerMenuTrigger.nextElementSibling;
			if (menuDrawer.classList.contains('menu__drawer')) {
				const menu = menuDrawer.querySelector('.menu');
				let menuHeight = menu.getBoundingClientRect().height;

				footerMenuTrigger.addEventListener('click', (e) => {
					if (footerMenuTrigger.classList.contains('active')) {
						footerMenuTrigger.classList.remove('active');
						menuDrawer.style.height = 0 + 'px';
					} else {
						footerMenuTrigger.classList.add('active');
						menuDrawer.style.height = menuHeight + 10 + 'px';
					}
				});

				window.addEventListener('resize', throttle(() => {
					menuHeight = menu.getBoundingClientRect().height;
					if (footerMenuTrigger.classList.contains('active')) {
						menuDrawer.style.height = menuHeight + 10 + 'px';
					}
				}, 1000, this));
			}
		});
	}
}
