import {
	disableScroll,
	forEach
} from "./helpers";
import Glide
	from "@glidejs/glide";

export const team = () => {
	const teamSliders = document.querySelectorAll('.block--team');
	if (teamSliders.length > 0) {
		forEach(teamSliders, (index, teamSlider) => {
			const teamFilter = teamSlider.querySelector('.filter');
			const teamFilterButtons = teamFilter.querySelectorAll('.filter__entry');
			const glide = teamSlider.querySelector('.glide');
			const teamSlidesDrawer = glide.querySelector('.glide__slides');
			const teamSliderSlides = glide.querySelectorAll('.slide');
			const slidesToAdd = teamSliderSlides;

			if (glide) {
				const indicators = glide.querySelector('.glide__indicator');
				const indicatorCurrent = indicators.querySelector('.glide__indicator--current');
				const indicatorMax = indicators.querySelector('.glide__indicator--max');
				let gap = glide.getAttribute('data-gap') ? glide.getAttribute('data-gap') : 30;
				let perview = 3;
				let focusat = 0;

				let mounted = new Glide(glide, {
					type: 'slider',
					startAt: 0,
					gap: gap,
					perView: perview,
					focusAt: focusat,
					rewind: false,
					peek: {
						before: 0,
						after: 0
					},
					breakpoints: {
						1024: {
							perView: 2,
							peek: {
								before: 0,
								after: 80
							}
						},
						670: {
							perView: 1,
							peek: {
								before: 0,
								after: 60
							}
						}
					}
				}).mount();

				mounted.on('run', function() {
					const indexToDraw = mounted.index + 1;
					indicatorCurrent.innerHTML = indexToDraw < 10 ? '0' + indexToDraw : indexToDraw;
				});

				mounted.on('update', function() {
					const maxSlides = glide.querySelectorAll('.glide__slide').length;
					const indexToDraw = mounted.index + 1;
					indicatorCurrent.innerHTML = indexToDraw < 10 ? '0' + indexToDraw : indexToDraw;
					indicatorMax.innerHTML = maxSlides < 10 ? '0' + maxSlides : maxSlides;
				});

				forEach(teamFilterButtons, (index, teamFilterButton) => {
					teamFilterButton.addEventListener('click', () => {
						const activeFilterButton = teamFilter.querySelector('.active');
						const activeFilter = activeFilterButton.getAttribute('data-filter');
						const newActiveFilter = teamFilterButton.getAttribute('data-filter');

						if (activeFilter === 'all' && newActiveFilter !== 'all') {
							forEach(teamSliderSlides, (index, slide) => {
								const slideType = slide.getAttribute('data-type');
								if (newActiveFilter !== slideType) {
									slide.remove();
								}
							});
						} else if (activeFilter !== 'all' && newActiveFilter === 'all') {
							forEach(teamSliderSlides, (index, slide) => {
								slide.remove();
							});
							forEach(slidesToAdd, (index, newSlide) => {
								teamSlidesDrawer.appendChild(newSlide);
							});
						} else if (activeFilter !== 'all' && newActiveFilter !== 'all') {
							forEach(teamSliderSlides, (index, slide) => {
								slide.remove();
							});
							forEach(slidesToAdd, (index, newSlide) => {
								const newSlideType = newSlide.getAttribute('data-type');
								if (newActiveFilter === newSlideType) {
									teamSlidesDrawer.appendChild(newSlide);
								}
							});
						}
						mounted.update();
						mounted.go('<<');
						activeFilterButton.classList.remove('active');
						teamFilterButton.classList.add('active');
					});
				});
			}

			const modalToggles = glide.querySelectorAll('.slide__modal-toggle');
			const teamModals = teamSlider.querySelectorAll('.modal');
			const navbar = document.querySelector('.navbar');
			const contactButton = document.querySelector('.contact-button');
			const footer = document.querySelector('.footer');
			if (modalToggles.length > 0) {
				forEach(modalToggles, (index, toggle) => {
					const toggleTarget = toggle.getAttribute('data-modal-target');
					toggle.addEventListener('click', () => {
						forEach(teamModals, (index, modal) => {
							const modalName = modal.getAttribute('data-modal-name');
							if (toggleTarget === modalName) {
								modal.classList.add('active');
								navbar.classList.add('modal-active');
								contactButton.classList.add('modal-active');
								footer.classList.add('modal-active');
							}
						});
					});
				});
			}
		});
	}
}
