import {forEach} from "./helpers";

export const smoothScroll = () => {
	const triggers = document.querySelectorAll('a[href^="#"]');
	if (triggers.length > 0) {
		forEach(triggers, (index, trigger) => {
			trigger.onclick = function(e) {
				e.preventDefault();
				const hash = this.getAttribute('href');
				if (hash) {
					const target = document.querySelector(hash);
					if (target) {
						const headerOffset = 100;
						const elementPosition = getOffsetTop(target);
						const offsetPosition = elementPosition - headerOffset;

						window.scrollTo({
							top: offsetPosition,
							behavior: "smooth"
						});
					}
				}
			};
		});
	}
}

function getOffsetTop(element) {
	return element ? (element.offsetTop + getOffsetTop(element.offsetParent)) : 0;
}
