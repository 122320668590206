import {
	disableScroll,
	forEach
} from "./helpers";
import Glide
	from "@glidejs/glide";

export const bonus = () => {
	const bonusSliders = document.querySelectorAll('.block--bonuses');
	if (bonusSliders.length > 0) {
		forEach(bonusSliders, (index, bonusSlider) => {
			const bonusFilter = bonusSlider.querySelector('.filter');
			const bonusFilterButtons = bonusFilter.querySelectorAll('.filter__entry');
			const glide = bonusSlider.querySelector('.glide');
			const bonusSliderDrawer = glide.querySelector('.glide__slides');
			const bonusSliderSlides = glide.querySelectorAll('.slide');
			const slidesToAdd = bonusSliderSlides;

			if (glide) {
				const indicators = glide.querySelector('.glide__indicator');
				const indicatorCurrent = indicators.querySelector('.glide__indicator--current');
				const indicatorMax = indicators.querySelector('.glide__indicator--max');
				let gap = glide.getAttribute('data-gap') ? glide.getAttribute('data-gap') : 30;
				let perview = 3;
				let focusat = 0;

				let mounted = new Glide(glide, {
					type: 'slider',
					startAt: 0,
					gap: gap,
					perView: perview,
					focusAt: focusat,
					rewind: false,
					peek: {
						before: 0,
						after: 2
					},
					breakpoints: {
						1024: {
							perView: 2,
							peek: {
								before: 0,
								after: 80
							}
						},
						670: {
							perView: 1,
							gap: 20,
							peek: {
								before: 0,
								after: 60
							}
						}
					}
				}).mount();

				mounted.on('run', function() {
					const indexToDraw = mounted.index + 1;
					indicatorCurrent.innerHTML = indexToDraw < 10 ? '0' + indexToDraw : indexToDraw;
				});

				mounted.on('build.after', function() {
					const newDrawerWidth = parseFloat(bonusSliderDrawer.style.width.slice(0, -2)) + 40;
					bonusSliderDrawer.style.width = newDrawerWidth + 'px';
				});

				mounted.on('update', function() {
					const maxSlides = glide.querySelectorAll('.glide__slide').length;
					const indexToDraw = mounted.index + 1;
					indicatorCurrent.innerHTML = indexToDraw < 10 ? '0' + indexToDraw : indexToDraw;
					indicatorMax.innerHTML = maxSlides < 10 ? '0' + maxSlides : maxSlides;
					const newDrawerWidth = parseFloat(bonusSliderDrawer.style.width.slice(0, -2)) + 40;
					bonusSliderDrawer.style.width = newDrawerWidth + 'px';
				});

				forEach(bonusFilterButtons, (index, bonusFilterButton) => {
					bonusFilterButton.addEventListener('click', () => {
						const activeFilterButton = bonusFilter.querySelector('.active');
						const activeFilter = activeFilterButton.getAttribute('data-filter');
						const newActiveFilter = bonusFilterButton.getAttribute('data-filter');

						if (activeFilter === 'all' && newActiveFilter !== 'all') {
							forEach(bonusSliderSlides, (index, slide) => {
								const slideType = slide.getAttribute('data-type');
								if (newActiveFilter !== slideType) {
									slide.remove();
								}
							});
						} else if (activeFilter !== 'all' && newActiveFilter === 'all') {
							forEach(bonusSliderSlides, (index, slide) => {
								slide.remove();
							});
							forEach(slidesToAdd, (index, newSlide) => {
								bonusSliderDrawer.appendChild(newSlide);
							});
						} else if (activeFilter !== 'all' && newActiveFilter !== 'all') {
							forEach(bonusSliderSlides, (index, slide) => {
								slide.remove();
							});
							forEach(slidesToAdd, (index, newSlide) => {
								const newSlideType = newSlide.getAttribute('data-type');
								if (newActiveFilter === newSlideType) {
									bonusSliderDrawer.appendChild(newSlide);
								}
							});
						}
						mounted.update();
						mounted.go('<<');
						activeFilterButton.classList.remove('active');
						bonusFilterButton.classList.add('active');
					});
				});
			}

			const singleBonuses = bonusSlider.querySelectorAll('.bonus');
			const bonusModal = bonusSlider.querySelector('.bonus__modal');
			const navbar = document.querySelector('.navbar');
			const contactButton = document.querySelector('.contact-button');
			const footer = document.querySelector('.footer');
			if (singleBonuses.length > 0) {
				forEach(singleBonuses, (index, bonus) => {
					bonus.addEventListener('click', () => {
						const bonusName = bonus.querySelector('.bonus__title').querySelector('span').innerHTML;
						const bonusThumbnail = bonus.querySelector('.bonus__thumbnail').innerHTML;
						const modalHeader = bonusModal.querySelector('.form__header');
						const modalName = modalHeader.querySelector('.bonus-name');
						const modalImg = modalHeader.querySelector('.bonus-image');
						const formHidden = bonusModal.querySelector('.form__hidden');
						const formChosenBonus = formHidden.querySelector('#choosenbonus');

						modalName.innerHTML = 'Ihre gewählte Prämie: ' + bonusName;
						modalImg.innerHTML = bonusThumbnail;
						formChosenBonus.value = bonusName;

						bonusModal.classList.add('active');
						navbar.classList.add('modal-active');
						contactButton.classList.add('modal-active');
						footer.classList.add('modal-active');
					});
				});
			}
		});
	}
}
