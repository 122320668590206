import {
	forEach
} from "./helpers";

export const video = () => {
	const videoBlocks = document.querySelectorAll('.block--video');
	if (videoBlocks.length > 0) {
		forEach(videoBlocks, (index, videoBlock) => {
			const videoType = videoBlock.getAttribute('data-video-type');
			if (videoType === 'upload') {
				videoUpload(videoBlock);
			} else if (videoType === 'youtube') {
				videoEmbed(videoBlock);
				cookieBannerOpener(videoBlock);
			}
		});
	}
}

function videoUpload(videoBlock) {
	const video = videoBlock.querySelector('video');
	const videoThumbnail = videoBlock.querySelector('.video__thumbnail');
	const videoButton = videoBlock.querySelector('.video__play-button');

	videoButton.addEventListener('click', () => {
		videoThumbnail.classList.add('inactive');
		videoButton.classList.add('inactive');
		video.play();
		setTimeout(() => {
			videoThumbnail.remove();
			videoButton.remove();
		}, 350);
	});
}

function videoEmbed(videoBlock) {
	if (typeof Cookiebot !== 'undefined') {
		const videoEmbedContainer = videoBlock.querySelector('.video__embed');
		const videoID = videoEmbedContainer.getAttribute('data-video');
		const videoThumbnail = videoBlock.querySelector('.video__thumbnail');
		const videoButton = videoBlock.querySelector('.video__play-button');
		const videoNotice = videoBlock.querySelector('.video__notice');

		let iframePlayer;
		if (Cookiebot.consent.marketing) {
			const options = {
				videoID: videoID,
				host: 'https://www.youtube-nocookie.com',
				playerVars: {
					origin: window.location.host,
					autoplay: 0,
					mute: 0,
					controls: 0,
					playlist: videoID,
					loop: 1,
				},
				events: {
				}
			}
			iframePlayer = new YT.Player(videoEmbedContainer, options);
			if (videoNotice) {
				videoNotice.remove();
			}
			if (videoThumbnail.classList.contains('video__thumbnail--embed')) {
				videoThumbnail.classList.remove('video__thumbnail--embed');
			}videoButton.addEventListener('click', () => {
				iframePlayer.playVideo();
			});
		} else {
			videoButton.addEventListener('click', () => {
				Cookiebot.submitCustomConsent(true, true, true);
				const options = {
					videoID: videoID,
					host: 'https://www.youtube-nocookie.com',
					playerVars: {
						origin: window.location.host,
						autoplay: 0,
						mute: 0,
						controls: 0,
						playlist: videoID,
						loop: 1,
					},
					events: {
						'onReady': onPlayerReady,
					}
				}
				iframePlayer = new YT.Player(videoEmbedContainer, options);
				videoNotice.remove();
				function onPlayerReady(event) {
					event.target.playVideo();
				}
				if (videoThumbnail.classList.contains('video__thumbnail--embed')) {
					videoThumbnail.classList.remove('video__thumbnail--embed');
				}
			});
		}
		videoButton.addEventListener('click', () => {
			videoThumbnail.classList.add('inactive');
			videoButton.classList.add('inactive');
			setTimeout(() => {
				videoThumbnail.remove();
				videoButton.remove();
			}, 350);
		});
	}
}

window.addEventListener('CookiebotOnAccept', () => {
	if (Cookiebot.consent.marketing) {
		const videoBlocks = document.querySelectorAll('.block--video');
		if (videoBlocks.length > 0) {
			forEach(videoBlocks, (index, videoBlock) => {
				const videoType = videoBlock.getAttribute('data-video-type');
				if (videoType === 'youtube') {
					const videoEmbedContainer = videoBlock.querySelector('.video__embed');
					const videoID = videoEmbedContainer.getAttribute('data-video');
					const videoThumbnail = videoBlock.querySelector('.video__thumbnail');
					const videoButton = videoBlock.querySelector('.video__play-button');
					const videoNotice = videoBlock.querySelector('.video__notice');
					let iframePlayer;
					const options = {
						videoID: videoID,
						host: 'https://www.youtube-nocookie.com',
						playerVars: {
							origin: window.location.host,
							autoplay: 0,
							mute: 0,
							controls: 0,
							playlist: videoID,
							loop: 1,
						},
						events: {
						}
					}
					iframePlayer = new YT.Player(videoEmbedContainer, options);
					if (videoNotice) {
						videoNotice.remove();
					}
					if (videoThumbnail.classList.contains('video__thumbnail--embed')) {
						videoThumbnail.classList.remove('video__thumbnail--embed');
					}
					videoButton.addEventListener('click', () => {
						videoThumbnail.classList.add('inactive');
						videoButton.classList.add('inactive');
						iframePlayer.playVideo();
						setTimeout(() => {
							videoThumbnail.remove();
							videoButton.remove();
						}, 350);
					});
				}
			});
		}
	}
});

const cookieBannerOpener = (videoBlock) => {
	if (typeof Cookiebot !== 'undefined') {
		const videoNotice = videoBlock.querySelector('.video__notice');
		if (videoNotice) {
			const videoCookiebotOpener = videoNotice.querySelector('.js-cookiebot-opener');
			videoCookiebotOpener.addEventListener('click', (e) => {
				e.preventDefault();
				Cookiebot.show();
			});
		}
	}
}
