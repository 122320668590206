import {
	forEach
} from "./helpers";
import Glide
	from "@glidejs/glide";

export const careerTeaser = () => {
	const careerTeasers = document.querySelectorAll('.block--career-teaser');
	if (careerTeasers.length > 0) {
		forEach(careerTeasers, (index, careerTeaser) => {
			const glide = careerTeaser.querySelector('.glide');

			if (glide) {
				let gap = 15;
				let perview = 1;
				let focusat = 0;

				let mounted = new Glide(glide, {
					type: 'carousel',
					startAt: 0,
					gap: gap,
					perView: perview,
					focusAt: focusat,
					peek: {
						before: 0,
						after: 50
					}
				}).mount();
			}
		});
	}
}
