import {
	forEach
} from "./helpers";

export const newsList = () => {
	const newsLists = document.querySelectorAll('.block--news');
	if (newsLists.length > 0) {
		forEach(newsLists, (index, newsList) => {
			const newsFilterButtons = newsList.querySelectorAll('.filter__entry');
			const newsListDrawer = newsList.querySelector('.news__drawer');
			const newsListJsonUrl =  window.location.origin + '/' + newsListDrawer.getAttribute('data-json-url');
			const newsListMore = newsList.querySelector('.news__button');
			const maxPage = newsListDrawer.getAttribute('data-max-page');
			let page = newsListDrawer.getAttribute('data-page');
			let more = true;
			let filterBool = false;
			let filterActive = null;
			let counterResults = 0;

			/* FILTER */
			forEach(newsFilterButtons, (index, filter) => {
				filter.addEventListener('click', () => {
					counterResults = 0;
					page = 1;
					const filterTag = filter.getAttribute('data-filter');
					filterActive = filterTag;
					forEach(newsFilterButtons, (index, button) => {
						button.classList.remove('active');
					});
					filter.classList.add('active');

					/* GET NEWS ON FILTER CLICK */
					fetch(`${newsListJsonUrl}.json/page:${page}`)
								.then(function(response) {
									let r = response.json();
									return r;
								})
								.then(function(news) {
									newsListDrawer.innerHTML = '';
									forEach(news, (index, newsSingle) => {
										if (filterTag === 'all') {
											filterBool = false;
											counterResults++;
											newsListDrawer.innerHTML += drawNews(newsSingle);
										} else {
											if (newsSingle['tag'].indexOf(filterTag) !== -1) {
												console.log(newsSingle['title']);
												filterBool = true;
												counterResults++;
												newsListDrawer.innerHTML += drawNews(newsSingle);
											}
										}
										if (newsSingle['more'] === false) {
											more = false;
										} else {
											more = true;
										}
									});
									if (!more) {
										newsListMore.classList.add('inactive');
									} else {
										if (newsListMore.classList.contains('inactive')) {
											newsListMore.classList.remove('inactive');
										}
										if (counterResults < 3) {
											newsListMore.click();
										}
									}
								})
								.catch(error => {
									console.log('error', error);
									throw(error)
								});
					/* END GET NEWS ON FILTER CLICK */
				});
			});
			/* END FILTER *&

			/* LOAD MORE */
			if (newsListMore) {
				newsListMore.addEventListener('click', () => {
					page++;
					fetch(`${newsListJsonUrl}.json/page:${page}`)
								.then(function(response) {
									let r = response.json();
									return r;
								})
								.then(function (news) {
									if (filterBool) {
										forEach(news, (index, newsSingle) => {
											if (newsSingle['tag'].indexOf(filterActive) !== -1) {
												counterResults++
												newsListDrawer.innerHTML += drawNews(newsSingle);
												if (newsSingle['more'] === false) {
													more = false;
												} else {
													more = true;
												}
											}
										});
									} else {
										counterResults = 0;
										forEach(news, (index, newsSingle) => {
											newsListDrawer.innerHTML += drawNews(newsSingle);
											if (newsSingle['more'] === false) {
												more = false;
											} else {
												more = true;
											}
										});
									}
									if (!more) {
										newsListMore.classList.add('inactive');
									} else {
										if (newsListMore.classList.contains('inactive')) {
											newsListMore.classList.remove('inactive');
										}
										if (counterResults < 3 && page < maxPage) {
											newsListMore.click();
										}
									}
								})
								.catch(error => {
									console.log('error', error);
									throw(error);
								});
				});
			}
			/* END LOAD MORE */
		});
	}
}

function drawNews(data) {
	const title = data['title'];
	const url = data['url'];
	const date = data['date'];
	const thumbnail = data['thumbnail'];
	const excerpt = data['excerpt'];

	return `
	<div class="news-single">
		<div class="news-single__thumbnail">
			`+ thumbnail +`
		</div>

		<div class="news-single__content">
			<div class="news-single__date">`+ date +`</div>
			<h3 class="ff-meta-head ff-meta-head--fs-36-d ff-meta-head--fs-32-m news-single__title">`+ title +`</h3>
			<div class="news-single__excerpt">`+ excerpt +`</div>
			<a href="`+ url +`" title="Mehr erfahren" class="button button--medium button--text" target="_self">
				<div class="button__inner">
					<span class="button__label ff-meta-pro-cta ff-meta-pro-cta--fs-16-d ff-meta-pro-cta--fs-16-m">Mehr erfahren</span>
				</div>
			</a>
		</div>
	</div>
	`;
}
