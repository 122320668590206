import {forEach} from "./helpers";
import Glide
	from "@glidejs/glide";

export const images = () => {
	const images = document.querySelectorAll('.block--images');
	if (images.length > 0) {
		forEach(images, (index, imagesBlock) => {
			const glide = imagesBlock.querySelector('.glide');

			if (glide) {
				let gap = glide.getAttribute('data-gap') ? glide.getAttribute('data-gap') : 15;
				let perview = 1;
				let focusat = 0;

				let mounted = new Glide(glide, {
					type: 'carousel',
					startAt: 0,
					gap: gap,
					perView: perview,
					focusAt: focusat,
					peek: {
						before: 0,
						after: 50
					}
				}).mount();
			}
		});
	}
}
