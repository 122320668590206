import {emitResize} from "./helpers";
import {footer} from "./footer";
import {heroVideo} from "./hero-video";
import {images} from "./images";
import {usps} from "./usps";
import {quoteSlider} from "./quote-slider";
import {lazyVideo} from "./lazy-video";
import {accordion} from "./accordion";
import {careerTeaser} from "./career-teaser";
import {joblist} from "./joblist";
import {navbar} from "./navbar";
import {seminarList} from "./seminar-list";
import {search} from "./search";
import {newsList} from "./news-list";
import {current} from "./current";
import {team} from "./team";
import {numbersFacts} from "./numbers-facts";
import {numbersFactsSlider} from "./numbers-facts-slider";
import {bonus} from "./bonus";
import {video} from "./video";
import {customSelect} from "./custom-select";
import {form} from "./form";
import {formRequest} from "./form-request";
import {contactButton} from "./contact-button";
import {smoothScroll} from "./smoothscroll";
import {modal} from "./modal";
import {
	seminarSingle
} from "./seminar-single";

document.addEventListener('DOMContentLoaded', function() {
	setTimeout(() => {
		console.log('DOM Loaded');

		navbar();
		contactButton();
		footer();
		search()
		lazyVideo();

		accordion();
		bonus();
		careerTeaser();
		current();
		customSelect();
		form();
		formRequest();
		heroVideo();
		images();
		joblist();
		modal();
		newsList();
		numbersFacts();
		numbersFactsSlider()
		quoteSlider();
		seminarList();
		seminarSingle();
		smoothScroll();
		team();
		usps();
		video();
	}, 500);
	setTimeout(() => {
		emitResize();
	}, 700);
})
