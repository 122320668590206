export const formSubmit = async (form, notice) => {
	const formUrl = window.location.origin + '/home.json';
	const failure = 'Etwas ist schiefgelaufen, bitte versuchen Sie es später erneut oder melden Sie sich direkt per Mail an info@pvs.email.';
	const formSubmitButton = form.querySelector('.form__submit');
	const formType = form.getAttribute('data-form-type');
	try {
		const response = await fetch(formUrl,{
			method: 'POST',
			body: new FormData(form)
		});
		const { postdata, success, errors, failed } = await response.json();
		if(errors) {
			console.log(errors);
			const notices = form.querySelectorAll('.notice');
			notices.forEach((notice) => {
				notice.innerHTML = '';
			});
			for (let key in errors) {
				if (errors.hasOwnProperty(key)) {
					form.querySelector('#' + key).classList.add('error');
					form.querySelector('[for=' + key + ']').classList.add('error');
					form.querySelector('[data-for=' + key + ']').innerHTML = errors[key];
				}
			}
			setTimeout(() => {
				formSubmitButton.classList.remove('form__submit--disabled');
			}, 2000);
		} else if(failed) {
			form.querySelector('[data-for=notice]').innerHTML = failure;
		} else if(success) {
			console.log('success');
			window.dataLayer = window.dataLayer || [];
			window.dataLayer.push({
				'event': 'form_submit_success',
				'form_type': formType,
			});
			console.log(window.dataLayer);
			form.querySelector('[data-for=notice]').classList.add('notice--success');
			setTimeout(() => {
				form.querySelector('[data-for=notice]').innerHTML = 'Ihre Nachricht wurde verschickt.';
			},350);
			/*setTimeout(() => {
				formSubmitButton.classList.remove('form__submit--disabled');
			}, 10000);*/
		}
	} catch (error) {
		console.log(error);
		form.querySelector('[data-for=notice]').innerHTML = failure;
	}
}
