export const tabs = () => {
    const tabs = document.querySelectorAll('.tabs');
    if (tabs.length > 0) {
        tabs.forEach(tab => {
            const toggles = tab.querySelectorAll('.tabs__toggle');
            const drawers = tab.querySelectorAll('.tabs__drawer');
            if (toggles.length > 0 && drawers.length > 0) {
                toggles.forEach(toggle => {
                    toggle.addEventListener('click', () => {
                        drawers.forEach(drawer => { drawer.style.height = 0; });
                        toggles.forEach(toggle => { toggle.classList.remove('active'); });
                        const target = toggle.getAttribute('data-tab');
                        const drawer = drawers.item(parseInt(target));
                        toggle.classList.add('active');
                        drawer.style.height = drawer.getBoundingClientRect().height === 0 ? drawer.querySelector('.tabs__inner').offsetHeight + 'px' : 0;
                    });
                });
            }
        });
    }
}