import {
	forEach
} from "./helpers";

export const customSelect = () => {
	const customSelects = document.querySelectorAll('.form__select');
	if (customSelects.length > 0) {
		forEach(customSelects, (index, customSelect) => {
			const selectElements = customSelect.querySelector('select');
			const customSelectedDiv = document.createElement('div');
			customSelectedDiv.setAttribute('class', 'select-selected');
			customSelectedDiv.innerHTML = selectElements.options[selectElements.selectedIndex].innerHTML;
			customSelect.appendChild(customSelectedDiv);

			const allOptionsDiv = document.createElement('div');
			allOptionsDiv.setAttribute('class', 'select-items select-hide');
			forEach(selectElements, (index, selectElement) => {
				const singleOptionDiv = document.createElement('div');
				singleOptionDiv.innerHTML = selectElements.options[index].innerHTML;

				singleOptionDiv.addEventListener('click', function() {
					const select = this.parentNode.parentNode.querySelector('select');
					const selectedElement = this.parentNode.previousSibling;
					for (let i = 0; i < select.length; i++) {
						if (select.options[i].innerHTML === this.innerHTML) {
							select.selectedIndex = i;
							selectedElement.innerHTML = this.innerHTML;
							const sameAsSelected = this.parentNode.querySelectorAll('.same-as-selected');
							for (let j = 0; j < sameAsSelected.length; j++) {
								sameAsSelected[j].removeAttribute('class');
							}
							this.setAttribute('class', 'same-as-selected');
							break;
						}
					}
					selectedElement.click();
				});
				allOptionsDiv.appendChild(singleOptionDiv);
			});
			customSelect.appendChild(allOptionsDiv);

			customSelectedDiv.addEventListener('click', function(e) {
				e.stopPropagation();
				closeAllSelect(this);
				this.nextElementSibling.classList.toggle('select-hide');
				this.classList.toggle('select-arrow-active');
			});
		});
	}
}

function closeAllSelect(element) {
	let arrNo = [];
	const allItems = document.querySelectorAll('.select-items');
	const allSelectedItems = document.querySelectorAll('.select-selected');
	forEach(allSelectedItems, (index, allSelectedItem) => {
		if (element == allSelectedItem) {
			arrNo.push(index);
		} else {
			allSelectedItem.classList.remove('select-arrow-active');
		}
	});
	forEach(allItems, (index, allItem) => {
		if (arrNo.indexOf(index)) {
			allItem.classList.add('select-hide');
		}
	});
}

document.addEventListener('click', closeAllSelect);
