import {
	forEach
} from "./helpers";

export const formRequest = () => {
	const requestForms = document.querySelectorAll('.form--request');
	if (requestForms.length > 0) {
		forEach(requestForms, (index, requestForm) => {
			const requestContactTypeMail = requestForm.querySelector('#contactmail');
			const requestContactPhone = requestForm.querySelector('#contactphone');
			//const requestContactTypePost = requestForm.querySelector('#contactpost');
			const requestMail = requestForm.querySelector('#email');
			const requestMailHint = requestForm.querySelector('.form__hint--mail');
			//const requestStreet = requestForm.querySelector('#street');
			//const requestCity = requestForm.querySelector('#city');
			const requestPhone = requestForm.querySelector('#phone');
			const requestPhoneLabel = requestPhone.parentNode.querySelector('label');

			requestContactTypeMail.addEventListener('change', () => {
				if (requestContactTypeMail.checked) {
					requestMail.setAttribute('type', 'email');
					requestMailHint.classList.add('hidden');
				} else {
					requestMail.setAttribute('type', 'text');
					requestMailHint.classList.remove('hidden');
				}
			});

			/*requestContactTypePost.addEventListener('change', () => {
				if (requestContactTypePost.checked) {
					requestStreet.parentNode.classList.remove('hidden');
					requestStreet.required = true;
					requestCity.parentNode.classList.remove('hidden');
					requestCity.required = true;
				} else {
					requestStreet.parentNode.classList.add('hidden');
					requestStreet.required = false;
					requestCity.parentNode.classList.add('hidden');
					requestCity.required = false;
				}
			});*/

			requestContactPhone.addEventListener('change', () => {
				if (requestContactPhone.checked) {
					requestPhone.required = true;
					requestPhoneLabel.innerHTML = 'Telefon*';
				} else {
					requestPhone.required = false;
					requestPhoneLabel.innerHTML = 'Telefon';
				}
			});
		});
	}
}
