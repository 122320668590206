import {
	forEach
} from "./helpers";

export const seminarList = () => {
	const seminarLists = document.querySelectorAll('.block--seminar-list');
	if (seminarLists.length > 0) {
		forEach(seminarLists, (index, seminarList) => {
			const seminarListDrawer = seminarList.querySelector('.seminar-list__drawer');
			const seminarListJsonUrl = window.location.origin + '/' + seminarListDrawer.getAttribute('data-json-url');
			const seminarListMore = seminarList.querySelector('.seminar-list__button');
			let page = seminarListDrawer.getAttribute('data-page');
			let more = true;
			let counterResults = 0;

			if (seminarListMore) {
				seminarListMore.addEventListener('click', () => {
					console.log(seminarListJsonUrl);
					counterResults = 0;
					page++;
					fetch(`${seminarListJsonUrl}.json/page:${page}`)
								.then(function(response) {
									let r = response.json();
									return r;
								})
								.then(function(seminars) {
									forEach(seminars, (index, seminar) => {
										seminarListDrawer.innerHTML += drawSeminar(seminar);
										if (seminar['more'] === false) {
											more = false;
										} else {
											more = true;
										}
									});
									if (!more) {
										seminarListMore.classList.add('inactive');
									} else {
										if (seminarListMore.classList.contains('inactive')) {
											seminarListMore.classList.remove('inactive');
										}
									}
								})
								.catch(error => {
									console.log('error', error);
									throw(error);
								});
				});
			}
		});
	}
}

function drawSeminar(data) {
	const title = data['title'];
	const url = data['url'];
	const date = data['date'];
	const start = data['start'];
	const end = data['end'];
	const location = data['location'];

	return `
	<a href="`+ url +`" title="Zum Seminar `+ title +`" class="seminar-list__seminar seminar" target="_self">
		<div class="seminar__title">
			<h4 class="ff-meta-head ff-meta-head--fs-32-d ff-meta-head--fs-32-m seminar__headline">
				`+ title +`
				<svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path d="M20 1.91716V18.1324H17.2727V4.65294L1.90341 20.0702L0 18.1324L15.3693 2.7151H1.90341V0.0078125H18.0966L20 1.91716Z" fill="white"/>
				</svg>
			</h4>
		</div>
		<div class="seminar__infos">
			<div class="seminar__info info info--date">
				<span class="ff-meta-pro-cta ff-meta-pro-cta--fs-12-d ff-meta-pro-cta--fs-12-m tt-uppercase fw-bold info__label">Datum</span>
				<span class="ff-meta-pro-copy ff-meta-pro-copy--fs-14-d ff-meta-pro-copy--fs-14-m info_info">`+ date +`</span>
			</div>
			<div class="seminar__info info info--time">
				<span class="ff-meta-pro-cta ff-meta-pro-cta--fs-12-d ff-meta-pro-cta--fs-12-m tt-uppercase fw-bold info__label">Datum</span>
				<span class="ff-meta-pro-copy ff-meta-pro-copy--fs-14-d ff-meta-pro-copy--fs-14-m info_info">`+ start +` - `+ end +`</span>
			</div>
			<div class="seminar__info info info--location">
				<span class="ff-meta-pro-cta ff-meta-pro-cta--fs-12-d ff-meta-pro-cta--fs-12-m tt-uppercase fw-bold info__label">Ort</span>
				<span class="ff-meta-pro-copy ff-meta-pro-copy--fs-14-d ff-meta-pro-copy--fs-14-m info_info">`+ location +`</span>
			</div>
		</div>
	</a>
	`;
}
