import {
	forEach
} from "./helpers";
import Glide
	from "@glidejs/glide";

export const current = () => {
	const currentSliders = document.querySelectorAll('.block--current');
	if (currentSliders.length > 0) {
		forEach(currentSliders, (index, currentSlider) => {
			const filter = currentSlider.querySelector('.filter');
			const currentFilterButtons = currentSlider.querySelectorAll('.filter__entry');
			const glide = currentSlider.querySelector('.glide');
			const slidesDrawer = glide.querySelector('.glide__slides');
			const slides = glide.querySelectorAll('.slide');
			const slidesToAdd = slides;

			if (glide) {
				const indicators = glide.querySelector('.glide__indicator');
				const indicatorCurrent = indicators.querySelector('.glide__indicator--current');
				const indicatorMax = indicators.querySelector('.glide__indicator--max');
				let gap = glide.getAttribute('data-gap') ? glide.getAttribute('data-gap') : 30;
				let perview = 3;
				let focusat = 0;

				let mounted = new Glide(glide, {
					type: 'slider',
					startAt: 0,
					gap: gap,
					perView: perview,
					focusAt: focusat,
					rewind: false,
					peek: {
						before: 0,
						after: 150
					},
					breakpoints: {
						1336: {
							perView: 2
						},
						1024: {
							perView: 2,
							peek: {
								before: 0,
								after: 80
							},
						},
						670: {
							perView: 1,
							peek: {
								before: 0,
								after: 60
							},
						}
					}
				}).mount();

				mounted.on('run', function() {
					const indexToDraw = mounted.index + 1;
					indicatorCurrent.innerHTML = indexToDraw < 10 ? '0' + indexToDraw : indexToDraw;
				});

				mounted.on('update', function() {
					const maxSlides = glide.querySelectorAll('.glide__slide').length;
					const indexToDraw = mounted.index + 1;
					indicatorCurrent.innerHTML = indexToDraw < 10 ? '0' + indexToDraw : indexToDraw;
					indicatorMax.innerHTML = maxSlides < 10 ? '0' + maxSlides : maxSlides;
				});

				forEach(currentFilterButtons, (index, currentFilterButton) => {
					currentFilterButton.addEventListener('click', () => {
						const activeFilterButton = filter.querySelector('.active');
						const activeFilter = activeFilterButton.getAttribute('data-filter');
						const newActiveFilter = currentFilterButton.getAttribute('data-filter');

						if (activeFilter === 'all' && newActiveFilter !== 'all') {
							forEach(slides, (index, slide) => {
								const slideType = slide.getAttribute('data-type');
								if (newActiveFilter !== slideType) {
									slide.remove();
								}
							});
						} else if (activeFilter !== 'all' && newActiveFilter === 'all') {
							forEach(slides, (index, slide) => {
								slide.remove();
							});
							forEach(slidesToAdd, (index, newSlide) => {
								slidesDrawer.appendChild(newSlide);
							});
						} else if (activeFilter !== 'all' && newActiveFilter !== 'all') {
							forEach(slides, (index, slide) => {
								slide.remove();
							});
							forEach(slidesToAdd, (index, newSlide) => {
								const newSlideType = newSlide.getAttribute('data-type');
								if (newActiveFilter === newSlideType) {
									slidesDrawer.appendChild(newSlide);
								}
							});
						}
						mounted.update();
						mounted.go('<<');
						activeFilterButton.classList.remove('active');
						currentFilterButton.classList.add('active');
					});
				});
			}
		});
	}
}
