import {
	forEach
} from "./helpers";

export const joblist = () => {
	const joblists = document.querySelectorAll('.block--joblist');
	if (joblists.length > 0) {
		forEach(joblists, (index, joblist) => {
			const joblistDrawer = joblist.querySelector('.joblist__drawer');
			const JoblistJsonURL =  window.location.origin + '/' + joblistDrawer.getAttribute('data-json-url');
			const joblistMore = joblist.querySelector('.joblist__button');
			let page = joblistDrawer.getAttribute('data-page');
			let more = true;
			let counterResults = 0;

			if (joblistMore) {
				joblistMore.addEventListener('click', () => {
					counterResults = 0;
					page++;
					fetch(`${JoblistJsonURL}.json/page:${page}`)
								.then(function(response) {
									let r = response.json();
									return r;
								})
								.then(function(jobs) {
									forEach(jobs, (index, job) => {
										joblistDrawer.innerHTML += drawJob(job);
										if (job['more'] === false) {
											more = false;
										} else {
											more = true;
										}
									});
									if (!more) {
										joblistMore.classList.add('inactive');
									} else {
										if (joblistMore.classList.contains('inactive')) {
											joblistMore.classList.remove('inactive');
										}
									}
								})
								.catch(error => {
									console.log('error', error);
									throw(error);
								});
				});
			}
		});
	}
}

function drawJob(data) {
	const title = data['title'];
	const url = data['url'];
	const type = data['type'];
	const beginning = data['beginning'];
	const location = data['location'];

	return `
	<a href="`+ url +`" title="Zum Job `+ title +`" class="joblist__job job" target="_self">
		<div class="job__title">
			<h4 class="ff-meta-head ff-meta-head--fs-32-d ff-meta-head--fs-32-m job__headline">
				`+ title +`
				<svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path d="M20 1.91716V18.1324H17.2727V4.65294L1.90341 20.0702L0 18.1324L15.3693 2.7151H1.90341V0.0078125H18.0966L20 1.91716Z" fill="white"/>
				</svg>
			</h4>
		</div>
		<div class="job__infos">
			<div class="job__info info info--type">
				<span class="ff-meta-pro-cta ff-meta-pro-cta--fs-12-d ff-meta-pro-cta--fs-12-m fw-bold tt-uppercase info__label">Art</span>
				<span class="ff-meta-pro-copy ff-meta-pro-copy--fs-14-d ff-meta-pro-copy--fs-14-m info_info">`+ type +`</span>
			</div>
			<div class="job__info info info--date">
				<span class="ff-meta-pro-cta ff-meta-pro-cta--fs-12-d ff-meta-pro-cta--fs-12-m tt-uppercase fw-bold info__label">Datum</span>
				<span class="ff-meta-pro-copy ff-meta-pro-copy--fs-14-d ff-meta-pro-copy--fs-14-m info_info">`+ beginning +`</span>
			</div>
			<div class="job__info info info--location">
				<span class="ff-meta-pro-cta ff-meta-pro-cta--fs-12-d ff-meta-pro-cta--fs-12-m tt-uppercase fw-bold info__label">Ort</span>
				<span class="ff-meta-pro-copy ff-meta-pro-copy--fs-14-d ff-meta-pro-copy--fs-14-m info_info">`+ location +`</span>
			</div>
		</div>
	</a>
	`;
}
