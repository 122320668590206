import {
	disableScroll,
	enableScroll,
	forEach
} from "./helpers";

export const modal = () => {
	const modalButtons = document.querySelectorAll('.button--modal');
	const modals = document.querySelectorAll('.modal');
	const navbar = document.querySelector('.navbar');
	const contactButton = document.querySelector('.contact-button');
	const footer = document.querySelector('.footer');

	if (modalButtons.length > 0) {
		forEach(modalButtons, (index, modalButton) => {
			const possibleModal = modalButton.nextElementSibling;
			if (possibleModal.classList.contains('modal')) {
				modalButton.addEventListener('click', () => {
					possibleModal.classList.add('active');
					navbar.classList.add('modal-active');
					contactButton.classList.add('modal-active');
					footer.classList.add('modal-active');
				});
			}
		});
	}

	if (modals.length > 0) {
		forEach(modals, (index, modal) => {
			const modalClose = modal.querySelector('.modal__close');

			modalClose.addEventListener('click', () => {
				modal.classList.remove('active');
				navbar.classList.remove('modal-active');
				contactButton.classList.remove('modal-active');
				footer.classList.remove('modal-active');
			});
		});
	}
}
