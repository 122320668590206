export const seminarSingle = () => {
	const seminarTeaser = document.querySelector('.seminar-teaser');
	const seminarModal = document.querySelector('.seminar-modal');
	if (seminarTeaser && seminarModal) {
		const seminarModalOpener = seminarTeaser.querySelector('.button--form');
		seminarModalOpener.addEventListener('click', () => {
			seminarModal.classList.add('active');
		})
	}
}
