import {
	forEach,
	isInViewportTwo
} from "./helpers";

const numbersFactsFlags = [];

export const numbersFacts = () => {
	const numbersFactsWidgets = document.querySelectorAll('.block--numbers-facts');
	if (numbersFactsWidgets.length > 0) {
		forEach(numbersFactsWidgets, (index, numbersFacts) => {
			numbersFactsFlags[index] = false;

			const numbersFactsWrapper = numbersFacts.parentNode;
			const numberFactsNextSibling = numbersFactsWrapper.nextElementSibling;
			if (numberFactsNextSibling) {
				if (numberFactsNextSibling.classList.contains('block-wrapper')) {
					numberFactsNextSibling.classList.add('move-up');
				}
			}
		});
		window.addEventListener('scroll', numbersFactsScrollCallback);
	}
}

const numbersFactsScrollCallback = () => {
	const numbersFactsWidgets = document.querySelectorAll('.block--numbers-facts');
	forEach(numbersFactsWidgets, (index, numbersFacts) => {
		if (isInViewportTwo(numbersFacts)) {
			const entries = numbersFacts.querySelectorAll('.entry');
			if (entries.length > 0) {
				forEach(entries, (index, entry) => {
					const entryNumber = entry.querySelector('.entry__number');
					if (entryNumber) {
						const entryNumberEnd = parseFloat(entryNumber.innerHTML);
						animateNumber(value => {
							if (Number.isInteger(entryNumberEnd)) {
								entryNumber.textContent = value.toFixed(0);
							} else {
								entryNumber.textContent = value.toFixed(1).replace('.', ',')
							}
						}, 0, entryNumberEnd, 3000);
					}

					const circle = entry.querySelector('.circle-chart');
					if (circle) {
						const circleChart = circle.querySelector('.circle-chart__circle');
						circleChart.classList.remove('inactive');
						circleChart.classList.add('active');
					}
				});
			}
			numbersFactsFlags[index] = true;
		}
	});
	if (allAreTrue(numbersFactsFlags)) {
		window.removeEventListener('scroll', numbersFactsScrollCallback);
	}
}

const allAreTrue = (arr) => {
	return arr.every(element => element === true);
}

const animateNumber = (callback, from, to, duration) => {
	let start = null,
				animate = timestamp => {
					start = start || timestamp;
					let progress = Math.min((timestamp - start) / duration, 1);
					callback(progress * (to - from) + from);
					if(progress < 1) {
						window.requestAnimationFrame(animate);
					}
				};
	window.requestAnimationFrame(animate);
}
