import {
	forEach
} from "./helpers";
import {
	formSubmit
} from "./form-submit";

export const form = () => {
	const forms = document.querySelectorAll('.form');
	if (forms.length > 0) {
		forEach(forms, (index, form) => {
			form.addEventListener('submit', (e) => {
				e.preventDefault();
				const notice = form.parentNode.querySelector('.notice');

				const captcha = form.querySelector('.h-captcha');
				const captchaIframe = captcha.querySelector('iframe');
				const captchaWidgetID = captchaIframe.getAttribute('data-captcha-widget-id');
				const captchaError = form.querySelector('.form__error--captcha');
				const captchaValue = hcaptcha.getResponse(captchaWidgetID);

				const formSubmitButton = form.querySelector('.form__submit');
				formSubmitButton.classList.add('form__submit--disabled');

				//const captchaValue = true;

				if (!captchaValue) {
					captchaError.innerHTML = 'Bitte bestätigen Sie, dass sie kein Roboter sind';
					setTimeout(() => {
						formSubmitButton.classList.remove('form__submit--disabled');
					}, 2000);
				} else {
					captchaError.innerHTML = '';
					formSubmit(form, notice);
				}
			});
		});
	}
}
