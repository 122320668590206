import {
	disableScroll,
	enableScroll,
	forEach,
	throttle
} from "./helpers";

export const navbar = () => {
	const navbar = document.querySelector('.navbar');
	const navbarItemsWSub = navbar.querySelectorAll('.menu__item--has-children');
	const menu = navbar.querySelector('.navbar__menu');
	const menuDrawer = navbar.querySelector('.navbar__menu-drawer');
	const submenus = navbar.querySelectorAll('.menu__sub');

	if (!navbar.classList.contains('navbar--trade-fair')) {
		/* NAVBAR CLICK TOGGLE SUBMENU */
		if (navbarItemsWSub.length > 0) {
			forEach(navbarItemsWSub, (index, item) => {
				item.addEventListener('click', (e) => {
					e.preventDefault();
					const targetSubMenu = item.getAttribute('data-submenu');
					const subMenu = navbar.querySelector('[data-target-submenu="'+ targetSubMenu +'"]');
					if (subMenu.classList.contains('active')) {
						subMenu.classList.remove('active');
						item.classList.remove('active');
					} else {
						forEach(navbarItemsWSub, (index, itemWSub) => {
							itemWSub.classList.remove('active');
						});
						forEach(submenus, (index, submenu) => {
							submenu.classList.remove('active');
						})
						subMenu.classList.add('active');
						item.classList.add('active');
					}
				});
			});
		}

		/* TRANSITION FUNCTION ON SCROLL */
		window.addEventListener('scroll', () => {
			const scrollY = window.scrollY;
			if (scrollY > 178) {
				if (navbar.classList.contains('at-top')) {
					menuDrawer.classList.add('hidden');
				}
				setTimeout(() => {
					navbar.classList.remove('at-top');
				}, 350);
				setTimeout(() => {
					if (!navbar.classList.contains('at-top')) {
						menuDrawer.classList.remove('hidden');
					}
				}, 700);
			} else {
				if (!navbar.classList.contains('at-top')) {
					menuDrawer.classList.add('hidden');
				}
				setTimeout(() => {
					navbar.classList.add('at-top');
				}, 350);
				setTimeout(() => {
					if (navbar.classList.contains('at-top')) {
						menuDrawer.classList.remove('hidden');
					}
				}, 700);
			}
		});

		/* NAVBAR TOGGLE MOBILE */
		const navbarToggle = navbar.querySelector('.mobile-toggle');
		const burger = navbarToggle.querySelector('.burger');
		const buttons = navbar.querySelector('.navbar__buttons');
		navbarToggle.addEventListener('click', () => {
			if (navbarToggle.classList.contains('active')) {
				navbarToggle.classList.remove('active');
				navbar.classList.remove('active');
				menu.classList.remove('active');
				burger.classList.remove('active');
				buttons.classList.remove('active');
				enableScroll();
			} else {
				navbarToggle.classList.add('active');
				navbar.classList.add('active');
				menu.classList.add('active');
				burger.classList.add('active');
				buttons.classList.add('active');
				disableScroll(document.documentElement.scrollTop);
			}
		});

		/* TOGGLE FOR CUSTOMER LOGIN */
		const customerLogins = navbar.querySelectorAll('.customer-login');

		forEach(customerLogins, (index, customerLogin) => {
			const customerLoginToggle = customerLogin.querySelector('.customer-login__toggle');
			const customerLoginContent = customerLogin.querySelector('.customer-login__content');
			const customerLoginItems = customerLogin.querySelector('.customer-login__items');
			let customerLoginItemsHeight = customerLoginItems.getBoundingClientRect().height;

			customerLoginToggle.addEventListener('click', () => {
				if (customerLogin.classList.contains('active')) {
					customerLogin.classList.remove('active');
					customerLoginContent.style.height = 0 + 'px';
				} else {
					customerLogin.classList.add('active');
					customerLoginContent.style.height = customerLoginItemsHeight + 40 + 'px';
				}
			});

			window.addEventListener('resize', throttle(function() {
				customerLoginItemsHeight = customerLoginItems.getBoundingClientRect().height;
				if (customerLogin.classList.contains('active')) {
					customerLoginContent.style.height = customerLoginItemsHeight + 50 + 'px';
				}
			}, 1000, this));
		});
	}
}
