import {forEach} from "./helpers";
import Glide from "@glidejs/glide";

export const heroVideo = () => {
	const heroVideos = document.querySelectorAll('.hero-video');
	if (heroVideos.length > 0) {
		forEach(heroVideos, (index, heroVideo) => {
			const glide = heroVideo.querySelector('.glide');
			const arrows = glide.querySelector('.glide__arrows');
			const arrowLeft = arrows.querySelector('.glide__arrow--left');

			if (glide) {
				let gap = glide.getAttribute('data-gap') ? glide.getAttribute('data-gap') : 0;
				let perview = 1;
				let focusat = 0;

				let mounted = new Glide(glide, {
					type: 'carousel',
					startAt: 0,
					gap: gap,
					perView: perview,
					focusAt: focusat,
					autoplay: 5000,
				}).mount();
				/*mounted.on('build.after', () => {
					const activeSlide = glide.querySelector('.glide__slide--active');
					const activeSlideContent = activeSlide.querySelector('.slide__content');
					arrows.style.top = activeSlideContent.getBoundingClientRect().top + window.scrollY - 80 + 'px';
				});*/
				mounted.on('run', () => {
					arrowLeft.innerHTML = mounted.index + 1;
				});
				/*mounted.on('run.after', () => {
					const activeSlide = glide.querySelector('.glide__slide--active');
					const activeSlideContent = activeSlide.querySelector('.slide__content');
					arrows.style.top = activeSlideContent.getBoundingClientRect().top + window.scrollY - 80 + 'px';
				});*/
			}
		});
	}
}
