import {
	throttle
} from "./helpers";

export const contactButton = () => {
	const footer = document.querySelector('.footer');
	const connectButton = document.querySelector('.connect-button');
	const contactButton = document.querySelector('.contact-button');

	if (connectButton && contactButton) {
		const contactButtonToggle = contactButton.querySelector('.contact-button__toggle');
		const contactButtonContentDrawer = contactButton.querySelector('.contact-button__content-drawer');
		const contactButtonContent = contactButton.querySelector('.contact-button__content');
		const contactButtonClose = contactButton.querySelector('.contact-button__close');
		let contactButtonContentHeight = contactButtonContent.getBoundingClientRect().height;

		contactButtonToggle.addEventListener('click', () => {
			contactButtonContentDrawer.classList.add('active');
			contactButtonContentDrawer.style.height = contactButtonContentHeight + 25 + 'px';
		});

		contactButtonClose.addEventListener('click', () => {
			contactButtonContentDrawer.classList.remove('active');
			contactButtonContentDrawer.style.height =  0 + 'px';
		});

		window.addEventListener('resize', throttle(function() {
			contactButtonContentHeight = contactButtonContent.getBoundingClientRect().height;
			if (contactButtonContentDrawer.classList.contains('active')) {
				contactButtonContentDrawer.style.height = contactButtonContentHeight + 25 + 'px';
			}
		}, 1000, this));

		window.addEventListener('scroll', () => {
			const footerRect = footer.getBoundingClientRect();
			const footerTop = footerRect.top;
			if (footerTop <= window.innerHeight) {
				contactButton.style.bottom = (window.innerHeight - footerTop + 15) + 'px';
				connectButton.style.bottom = (window.innerHeight - footerTop + 100) + 'px';
			} else {
				contactButton.style.bottom = '';
				connectButton.style.bottom = '';
			}
		});
	}
}
