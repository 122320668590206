import {
	forEach,
	throttle
} from "./helpers";

export const accordion = () => {
	const accordions = document.querySelectorAll('.block--accordion');
	if (accordions.length > 0) {
		forEach(accordions, (index, accordion) => {
			const entries = accordion.querySelectorAll('.entry');
			forEach(entries, (index, entry) => {
				const entryTitle = entry.querySelector('.entry__title');
				const entryDrawer = entry.querySelector('.entry__drawer');
				const entryContent = entry.querySelector('.entry__content');
				let entryContentHeight = entryContent.getBoundingClientRect().height;

				entryTitle.addEventListener('click', () => {
					if (entryDrawer.classList.contains('active')) {
						entryDrawer.classList.remove('active');
						entryDrawer.style.height = 0 + 'px';
						entryTitle.classList.remove('active');
					} else {
						entryDrawer.classList.add('active');
						entryDrawer.style.height = entryContentHeight + 50 + 'px';
						entryTitle.classList.add('active');
					}
				});

				window.addEventListener('resize', throttle(function() {
					entryContentHeight = entryContent.getBoundingClientRect().height;
					if (entryDrawer.classList.contains('active')) {
						entryDrawer.style.height = entryContentHeight + 50 + 'px';
					}
				}, 1000, this));
			});
		});
	}
}
