import {
	disableScroll,
	enableScroll,
	forEach
} from "./helpers";

export const search = () => {
	const searchOpeners = document.querySelectorAll('.button--search');
	const search = document.querySelector('.search');

	if (searchOpeners && search) {
		const searchClose = search.querySelector('.search__close');
		forEach(searchOpeners, (index, searchOpener) => {
			searchOpener.addEventListener('click', () => {
				disableScroll(document.documentElement.scrollTop);
				search.classList.add('active');
			});
		});

		searchClose.addEventListener('click', () => {
			enableScroll();
			search.classList.remove('active');
		});
	}
}
